<template>
  <div class="body-bg">
    
    <van-nav-bar
      title="提现"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >

      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    
    <div class="container">
      <van-cell-group :border="false">
        <van-cell :border="false">
          <template #title>
            <span class="title">提现金额</span>
          </template>
        </van-cell>
        <van-field type="number">
          <template #left-icon>
            <van-icon
              class="iconfont"
              class-prefix="icon"
              name="money"
              size="16"
              color="#333"
            />
          </template>
          <template #input>
            <input class="input" type="number" v-model="money" />
          </template>
          <template #button>
            <span class="all" @click="allGet">全部提现</span>
          </template>
        </van-field>
        <van-cell :border="false">
          <template #title>
            <span class="title-tip">可提现金额{{ allMoney }}元</span>
          </template>
        </van-cell>
        <van-cell title="提现到"></van-cell>
        <div class="crash-item-box">
          <div class="item-left">
            <div class="item-icon">
              <van-icon
                class="iconfont"
                class-prefix="icon"
                name="pay-wx"
                size="26"
                color="#00CC00"
              />
            </div>
            <div class="item-text">
              <p>微信</p>
            </div>
          </div>
          <div class="item-right">
            <van-checkbox
              v-model="checked"
              checked-color="#F5C204"
              icon-size="18"
            ></van-checkbox>
          </div>
        </div>
      </van-cell-group>
    </div>
    <div class="action-bar">
      <div class="button">
        <van-button round block type="info" color="#F3C301" @click="crash" v-preventRepeatClick="3000"
          ><p class="color333">确认提现</p></van-button
        >
      </div>
      <p class="tips">
        <span class="p1">注意事项：</span>
        <span class="p2"
          >请耐心等待，关注微信钱包。</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/user";
import { Toast } from 'vant';
export default {
  data() {
    return {
      checked: true,
      money: 0.00,
      allMoney: 0.00
    };
  },
  async beforeCreate() {
    //得到用户统计信息
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await UserService.staffInfo(this, params);
    if (resp1.data.code == 10000) {
      this.allMoney = resp1.data.data.staff_balance;
    }
  },
  methods: {
    goHome: function() {
      this.$router.push("/");
    },
    allGet: function(){
      this.money = this.allMoney;
    },
    goBack: function() {
      this.$router.go(-1);
    },
    //提现,并跳转到提现结果页面
    async crash() {
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      if(this.money>this.allMoney) {
        Toast("提现金额不能大于可提现金额!");
        return;
      }
      var params = {
        token: token,
        money: this.money,
      };
      const resp1 = await UserService.applyCashOut(this, params);
      if (resp1.data.code == 10000) {
        this.$router.push("/person/wallet/crashResult");
      }else {
        Toast(resp1.data.msg);
      }
      
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  background-color: #fff;
}
.all {
  font-size: 13px;
  color: #f5c204;
}
.title {
  font-size: 13px;
  color: #333;
}
.title-tip {
  font-size: 13px;
  color: #b3b3b3;
}
.crash-item-box {
  padding: 10px 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #f5f5f5;
}
.crash-item-box .item-right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
}

.crash-item-box .item-left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  justify-items: center;
}
.item-left .item-icon {
  margin-right: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
}

.item-left .item-text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
}
.item-text p {
  font-size: 13px;
  color: #333;
}

.action-bar {
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.action-bar .tips {
  color: #b3b3b3;
  font-size: 12px;
  padding: 0 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.tips .p1 {
  text-align: center;
}
.action-bar .button {
  margin-bottom: 10px;
  width: 100%;
}
.input {
  border: none;
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
</style>
